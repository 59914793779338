import React from "react";

const Banner = (props) => {
  const { Title, SubTitle, Image, Logo } = props;

  return (
    <div
      className={`box-image box-image-alt`}
      style={{
        backgroundImage: `url(${Image})`,
      }}
    >
      <div className="banner banner-alt">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="font-lg-h1 heading heading-white">{Title}</h2>
              {SubTitle !== "" && (
                <div className="font-h5 mb-0 font-weight-light">{SubTitle}</div>
              )}
              {Logo !== undefined && <img src={`${Logo}`} alt="" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
