import React from "react";
import Layout from "../components/Layout";
import Link from "../components/Link";
import Banner from "../components/Banner";
import Client from "../components/Client";
import Contact from "../components/Contact";

import Head from "../components/Head";
import { Invoice } from "./../data/SEO";
import Config from "./../data/Data";

const PageInvoice = () => {
  const { linkEmite } = Config;

  return (
    <Layout>
      <Head Title={Invoice[0].title} Description={Invoice[0].description} />

      <Banner
        Title={
          <>
            Facturación
            <br />
            Electrónica
          </>
        }
        SubTitle=""
        Image="/assets/images/banner/invoice.png"
        Logo="assets/images/authorize.svg"
      />

      <div className="bg-blue-100 pt-lg-5">
        <div className="py-lg-3">
          <div className="container py-5">
            <div className="row justify-content-between">
              <div className="col-lg-5">
                <div className="d-flex align-items-center mb-4">
                  <div>
                    <img
                      src={"/assets/images/emite.png"}
                      height="80"
                      alt="Facturación electrónica Emite"
                    />
                  </div>
                  <div className="ml-5">
                    <img src={"/assets/images/iso.png"} alt="" />
                  </div>
                </div>
                <div>
                  <p>
                    <strong>
                      Solución online de gestión y facturación electrónica
                    </strong>
                    que reduce y simplifica los proceso de toma de decisiones. 
                    Además, de ofrecer un control absoluto de los procesos de su negocio.
                  </p>
                </div>
                <div>
                  <img src={"/assets/images/sunat.svg"} alt="" />
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <p className="h5 mb-4 text-green">
                  Emitiendo facturas electrónicas <br />
                  cuidamos el Medio Ambiente
                </p>
                <div>
                  <img src={"/assets/images/medio-ambiente.svg"} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-lg-5">
        <div className="row align-items-center py-5">
          <div className="col-lg-5">
            <h2 className="h3 font-lg-h2 heading">Características</h2>
            <div className="mt-4 font-lg-h5 font-weight-normal mb-4 mb-lg-0">
              <ul>
                <li>Registro de información</li>
                <li>Generación de reportes</li>
                <li>Consulta de saldos y otros</li>
                <li>Gestiona y realiza cobranzas</li>
                <li>Gestión de comprobantes</li>
                <li>Y todavías más características</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 text-center">
            <img src={"/assets/images/invoice/1.png"} alt="" />
          </div>
        </div>

        <div className="py-lg-5">
          <div className="row flex-row-reverse align-items-center py-5">
            <div className="col-lg-6">
              <div className="pl-lg-3">
                <h2 className="h3 font-lg-h2 heading">Beneficios</h2>
                <div className="mt-4 font-lg-h5 font-weight-normal mb-4 mb-lg-0">
                  <ul>
                    <li>Fácil y rápido</li>
                    <li>100% seguro y confiable</li>
                    <li>Almacenamiento en la nube</li>
                    <li>65% de ahorro en impresión</li>
                    <li>Sistema offline de respaldo</li>
                    <li>Personalización y más...</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={"/assets/images/invoice/2.png"} alt="" />
            </div>
          </div>
        </div>

        <div className="row align-items-center py-5">
          <div className="col-lg-5">
            <h2 className="h3 font-lg-h2 heading">Certificado</h2>
            <div className="mt-4 font-lg-h5 font-weight-normal mb-4 mb-lg-0">
              Óbten tu certificado digital con Doous de forma rápida y sencilla.
              Con tu certificado digital brindas conexiones seguras de los datos
              personales de tu plataforma digital
            </div>
          </div>
          <div className="col-lg-7 text-center">
            <img src={"/assets/images/invoice/3.png"} alt="" />
          </div>
        </div>

        <div className="text-center my-5 pb-5">
          <div className="mb-0 font-h5 font-weight-normal">
            Vea más características de Emite
          </div>
          <div className="mt-3">
            <Link
              Url={linkEmite}
              Class="btn-primary-outline"
              Icon="fa-external-link-alt"
              Name="Ver página"
            />
          </div>
        </div>

        <h2 className="font-lg-h1 mb-5 heading">Ellos ya confían en Emite</h2>

        <div className="d-flex flex-wrap justify-content-center mx-n3 consulting-clients">
          <Client Image="/assets/images/clients/invoice/toolcraft.png" />
          <Client Image="/assets/images/clients/invoice/cardiomed-peru.png" />
          <Client Image="/assets/images/clients/invoice/stranssantos.png" />
          <Client Image="/assets/images/clients/invoice/titan-fashion.png" />
          <Client Image="/assets/images/clients/invoice/elcisne.png" />
          <Client Image="/assets/images/clients/invoice/inversiones-puritacalidad.png" />
          <Client Image="/assets/images/clients/invoice/pm-servicios.png" />
          <Client Image="/assets/images/clients/invoice/fabri-medical.png" />
          <Client Image="/assets/images/clients/invoice/labsystems.png" />
          <Client Image="/assets/images/clients/invoice/lassac.png" />
          <Client Image="/assets/images/clients/invoice/servitest.png" />
          <Client Image="/assets/images/clients/invoice/pce-peru.png" />
          <Client Image="/assets/images/clients/invoice/halema.png" />
          <Client Image="/assets/images/clients/invoice/ventura-import.png" />
          <Client Image="/assets/images/clients/invoice/illa-hotel.png" />
        </div>

        <Contact
          Description="¡Descubre cómo ayudamos a nuestros clientes!"
          Title="Contáctenos"
        />
      </div>
    </Layout>
  );
};

export default PageInvoice;
