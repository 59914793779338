import React from "react";
import Data from "./../data/Data";

const Share = () => {
  const { shareLinks } = Data;

  return shareLinks.map((link, i) => (
    <li key={i} className={i === 1 ? "mx-4" : ""}>
      <a href={link.url} className="none" target="_blank" rel="noreferrer">
        <span className="social-circle">
          <i className={`fab ${link.iconClassName}`}></i>
        </span>
      </a>
    </li>
  ));
};

export default Share;
