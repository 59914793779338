import React from "react";

const Box = (props) => {
  const { Image, Align, Alt } = props;

  return (
    <div className={`box-info p-2 ${Align}`}>
      <img src={`${Image}`} className="d-block w-100" alt={`${Alt}`} />
    </div>
  );
};

export default Box;
