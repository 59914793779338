import React from "react";
import Layout from "../components/Layout";
import Box from "../components/Box";
import Link from "../components/Link";
import Banner from "../components/Banner";
import Head from "../components/Head";
import { Labs } from "./../data/SEO";
import Config from "./../data/Data";

const PageLabs = () => {
  const { linkEmite, linkAzu, linkERP } = Config;

  return (
    <Layout>
      <Head Title={Labs[0].title} Description={Labs[0].description} />

      <Banner
        Title={
          <>
            Laboratorio
            <br />
            de Innovación
          </>
        }
        SubTitle="Nuestros proyectos"
        Image="/assets/images/banner/labs.png"
      />

      <div className="container py-lg-5">
        <div className="row align-items-center py-5">
          <div className="col-lg-5">
            <div className="mb-4">
              <img
                src={"/assets/images/emite.png"}
                height="80"
                alt="Facturación electrónica Emite"
              />
            </div>
            <p>
              <strong>
                Solución online de gestión y facturación electrónica
              </strong>
              que reduce y simplifica los proceso de toma de decisiones. Además,
              de ofrecer un control absoluto de los procesos de su negocio.
            </p>
            <div className="mt-3 pb-4 pb-lg-0">
              <Link
                Url={linkEmite}
                Class="btn-primary-outline"
                Icon="fa-external-link-alt"
                Name="Ver página"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="position-relative">
              <div className="text-right">
                <img src={"/assets/images/labs/emite.png"} alt="" />
              </div>

              <Box Image="/assets/images/labs/emite-image.png" Alt="Facturación" />
            </div>
          </div>
        </div>

        <div className="row flex-row-reverse align-items-center py-lg-5">
          <div className="col-lg-5">
            <div className="pl-lg-3">
              <div className="mb-4">
                <img
                  src={"/assets/images/azu.png"}
                  height="100"
                  alt="Azu! App"
                />
              </div>
              <p>
                <strong>
                Es una solución de gestión de las relaciones con clientes,
                </strong>
                &nbsp;orientada normalmente a gestionar tres áreas básicas: la gestión comercial, el
                marketing y el servicio postventa o de atención al cliente.
              </p>
              <div className="mt-3 pb-4 pb-lg-0">
                <Link
                  Url={linkAzu}
                  Class="btn-primary-outline"
                  Icon="fa-external-link-alt"
                  Name="Ver página"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="position-relative">
              <img src={"/assets/images/labs/azu-motorizado.png"} alt="" />

              <Box
                Image="/assets/images/labs/azu-image.png"
                Alt="Uso de aplicativo"
                Align="box-info--right"
              />
            </div>
          </div>
        </div>

        <div className="row align-items-center py-5">
          <div className="col-lg-5">
            <div className="mb-4">
              <img src={"/assets/images/pousser.png"} height="80" alt="Pousser" />
            </div>
            <p>
              <strong>
              Innovacion en tiempo real.
              </strong>
              &nbsp;Pousser es una solución innovadora, que permite conectar sus sistemas en tiempo real.
            </p>
            <div className="mt-3 pb-4 pb-lg-0">
              <Link
                Url={linkERP}
                Class="btn-primary-outline"
                Icon="fa-external-link-alt"
                Name="Ver página"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="position-relative">
              <div className="text-right">
                <img src={"/assets/images/labs/3.png"} alt="" />
              </div>

              <Box
                Image="/assets/images/labs/3-image.png"
                Alt="Mapa conceptual"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageLabs;
