import React from "react";

const Link = (props) => {
  const { Url, Class, Icon, Name } = props;

  return (
    <a href={Url} className={`btn ${Class}`}>
      <span className="mr-2 pt-px-3">{Name}</span>

      <i className={`fas ${Icon}`}></i>
    </a>
  );
};

export default Link;
