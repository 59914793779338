import React, { Fragment } from "react";

const Counter = (props) => {
  const { Number, Title } = props;

  return (
    <Fragment>
      <div className="font-display font-weight-medium">
        +
        <span
          className="number-value"
          data-number-start="1"
          data-number-end={Number}
          data-number-speed="3000"
        >
          {Number}
        </span>
      </div>
      <div className="">{Title}</div>
    </Fragment>
  );
};

export default Counter;
