export const Index = [
  {
    title: "Doous - Soluciones inteligentes",
    description:
      "Soluciones tecnológicas, diseño y creación de aplicativos y portales web, intranets, ERPs, entre otros. Trabajamos de forma ágil para crear soluciones escalables.",
  },
];

export const About = [
  {
    title: "Nosotros",
    description:
      "Creamos soluciones para aumentar la eficiencia y eficacia de los procesos de nuestros clientes, para hacerlos crecer de forma ágil y escalable.",
  },
];

export const Consulting = [
  {
    title: "Consultoría de Desarrollo App y Web",
    description:
      "Desarrollamos para Aplicativos Móviles y Web, especialistas creando soluciones en diferentes tecnologías como: Angular, React, Vue, .NET, Java, ect.",
  },
];

export const Contact = [
  {
    title: "Contáctenos",
    description:
      "Estamos dispuestos en solucionar cualquier inconveniente y brindar soluciones a sus necesidades, dispones de varios canales de contacto.",
  },
];

export const Error = [
  {
    title: "Página no encontrada",
    description:
      "Error 404, Esto puede deberse porque la página no existe o la ruta ha sido cambiado por una nueva.",
  },
];

export const Invoice = [
  {
    title: "Facturación Electrónica",
    description:
      "Sistema de Facturación Electrónica Emite, realiza registros, reportes, consultas, cobranzas, ventas, entre otros. Es rápido y seguro, además incluye almacenamiento en la nube y servicio offline",
  },
];

export const Labs = [
  {
    title: "Laboratorio de Innovación",
    description:
      "En Doous creamos productos digitales innovadores y tenemos un compromiso en probar nuevas tecnologías de manera constante para brindar soluciones completas a nuestros clientes.",
  },
];
