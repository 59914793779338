import React from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PageIndex from "./pages/Index";
import PageAbout from "./pages/About";
import PageLabs from "./pages/Labs";
import PageInvoice from "./pages/Invoice";
import PageConsulting from "./pages/Consulting";
import PageContact from "./pages/Contact";
import Page404 from "./pages/error404";

const Routes = () => {
  return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={PageIndex} />
          <Route path="/nosotros" component={PageAbout} />
          <Route path="/laboratorio" component={PageLabs} />
          <Route path="/facturacion-electronica" component={PageInvoice} />
          <Route path="/consultoria" component={PageConsulting} />
          <Route path="/contacto" component={PageContact} />
          <Route path="*" component={Page404} />
        </Switch>
      </BrowserRouter>
  );
};

export default Routes;
