import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Client from "../components/Client";
import Contact from "../components/Contact";

import Head from "../components/Head";
import { Consulting } from "./../data/SEO";

const PageConsulting = () => {
  return (
    <Layout>
      <Head
        Title={Consulting[0].title}
        Description={Consulting[0].description}
      />

      <Banner
        Title={
          <>
            Consultoría
            <br />
            de Software
          </>
        }
        SubTitle="Servicios que ofrecemos"
        Image="/assets/images/banner/consulting.png"
      />

      <div className="pt-lg-5">
        <div className="container py-md-5">
          <div className="row align-items-center py-5">
            <div className="col-md-5">
              <h2 className="font-lg-h1 heading">
                Desarrollo de <span className="d-block">Aplicativos</span>
              </h2>
              <div className="mt-4 font-lg-h5 font-weight-normal mb-0">
                Solución online de gestión y facturación electrónica que reduce
                y simplifica los proceso de toma de decisiones. Además, de
                ofrecer un control absoluto de los procesos de su negocio.
              </div>
              <div className="mt-3">
                <img src={"/assets/images/apps.svg"} alt="" />
              </div>
            </div>
            <div className="col-md-7 text-center">
              <div className="pt-5 pt-md-0">
                <img
                  src={"/assets/images/consulting/1.png"}
                  alt="Desarrollo de aplicativos"
                />
              </div>
            </div>
          </div>

          <div className="py-lg-5">
            <div className="row flex-row-reverse align-items-center py-5">
              <div className="col-md-6">
                <div className="pl-lg-5 pl-xl-3">
                  <h2 className="font-lg-h1 heading">
                    Desarrollo de <span className="d-block">Plataformas</span>
                  </h2>
                  <div className="mt-4 font-lg-h5 font-weight-normal mb-0 pr-lg-5">
                    Brindamos soluciones multiplataformas para generar valor a
                    tu negocio. Nos adaptamos a las necesidades más exigentes
                    para hacerlo compatible con cualquier dispositivo del
                    mercado.
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pt-5 pt-md-0">
                  <img
                    src={"/assets/images/consulting/2.png"}
                    alt="Desarrollo de plataformas"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center py-5">
            <div className="col-md-5">
              <h2 className="font-lg-h1 heading">
                Desarrollo <span className="d-block">a Medida</span>
              </h2>
              <div className="mt-4 font-lg-h5 font-weight-normal mb-0">
                Desarrollo de soluciones a medida según las necesidades de su
                negocio, trabajamos con cualquier tipo de tecnología.
              </div>
              <div className="mt-4">
                <img src={"/assets/images/software.svg"} height="45" alt="" />
              </div>
            </div>
            <div className="col-md-7 text-center">
              <div className="pt-5 pt-md-0">
                <img
                  src={"/assets/images/consulting/3.png"}
                  alt="Desarrollo a medida"
                />
              </div>
            </div>
          </div>

          <div className="mt-5 pt-5">
            <h2 className="font-lg-h1 mb-5 heading">
              Ellos ya confían en Doous
            </h2>
          </div>

          <div className="d-flex flex-wrap mx-n3 consulting-clients">
            <Client Image="/assets/images/clients/consulting/ivvit.png" />
            <Client Image="/assets/images/clients/consulting/scytl.png" />
            <Client Image="/assets/images/clients/consulting/soltv.png" />
            <Client Image="/assets/images/clients/consulting/elcisne.png" />
            <Client Image="/assets/images/clients/consulting/bankea.png" />
          </div>

          <Contact
            Description="¡Descubre cómo ayudamos a nuestros clientes!"
            Title="Contáctenos"
          />
        </div>
      </div>
    </Layout>
  );
};

export default PageConsulting;
