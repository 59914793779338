import React from "react";

const Client = (props) => {
  const { Image, Alt } = props;

  return (
    <div className="w-50 w-lg-20 consulting-clients-item">
      <img src={Image} className="img-fluid mx-auto" alt={Alt} />
    </div>
  );
};

export default Client;
