import React from "react";

const Contact = (props) => {
  const { Description, Title } = props;

  return (
    <div className="text-center my-3 py-4 pb-lg-0 pt-lg-5">
      <div className="mb-0 font-h5 font-weight-normal">{Description}</div>
      <div className="mt-3">
        <a href="/contacto" className="btn btn-primary-outline">
          {Title}
        </a>
      </div>
    </div>
  );
};

export default Contact;
