import React, { Fragment } from "react";

const Slider = (props) => {
  const { Title, Description, Image } = props;

  return (
    <Fragment>
      <div>
        <div
          className="slider-item box-image"
          style={{
            backgroundImage: `url(${Image})`,
          }}
        >
          <div className="banner">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="font-lg-h1 heading heading-white">{Title}</h2>
                  <div className="font-h5 mb-0 font-weight-light">
                    {Description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Slider;
