import React, { Component } from "react";
import Layout from "../components/Layout";

import TinySlider from "tiny-slider-react";
import Testimony from "../components/Testimony";
import Counter from "../components/Number";

import Head from "../components/Head";
import { Index } from "./../data/SEO";
import LoadableSlider from "./LoadableSlider";

class PageIndex extends Component {
  render() {

    const settingTestimony = {
      lazyload: true,
      navPosition: "bottom",
      mouseDrag: true,

      //auto
      autoplay: true,
      controls: false,

      //botón stop
      autoplayButtonOutput: false,

      loop: true,
      items: 1,
      speed: 2000,
      autoplayTimeout: 4000,
    };

    return (
      <Layout>
        <Head
          Title={Index[0].title}
          Description={Index[0].description}
          isSiteName={true}
        />

        <LoadableSlider/>

        <div className="container py-lg-5">
          <div className="row align-items-center py-5 flex-row-reverse">
            <div className="col-lg-7 move-invoice">
              <div className="position-relative pb-4 pb-lg-0">
                <div className="text-right">
                  <img src={"/assets/images/index/1.png"} alt="" />
                </div>

                <div className="box-info">
                  Soluciones online de gestión y facturación electrónica que se
                  pueden integrar con otros sistemas informáticos.
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <h2 className="font-lg-h1 heading">
                Facturación <span className="d-block">Electrónica</span>
              </h2>
              <div className="mt-3">
                <a href="/facturacion-electronica" className="btn btn-primary-outline">
                  CONOZCA MÁS
                </a>
              </div>
            </div>
          </div>

          <div className="row align-items-center py-lg-5">
            <div className="col-lg-7 move-consulting">
              <div className="position-relative pb-4 pb-lg-0">
                <img src={"/assets/images/index/desarrollo.png"} alt="Desarrollo" />

                <div className="box-info box-right">
                  Expertos en desarrollo de app móviles, diseño web y modelo de
                  negocios en internet con la tecnología más avanzada.
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="pl-lg-3">
                <h2 className="font-lg-h1 heading">
                  Consultoría <span className="d-block">de Software</span>
                </h2>
                <div className="mt-3">
                  <a href="/consultoria" className="btn btn-primary-outline">
                    CONOZCA MÁS
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center py-5 flex-row-reverse">
            <div className="col-lg-7 move-labs">
              <div className="position-relative pb-4 pb-lg-0">
                <div className="text-right">
                  <img src={"/assets/images/index/3.png"} alt="" />
                </div>

                <div className="box-info">
                  Creamos e innovamos de manera constante, por ello, también
                  disponemos de proyectos propios con las últimas tecnologías
                  del mercado.
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <h2 className="font-lg-h1 heading">
                Laboratorio <span className="d-block">de Innovación</span>
              </h2>
              <div className="mt-3">
                <a href="/laboratorio" className="btn btn-primary-outline">
                  CONOZCA MÁS
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="about"
          style={{ backgroundImage: `url("assets/images/about.png")` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 offset-lg-6">
                <div className="pl-lg-5">
                  <h2 className="font-lg-h1 heading">Nosotros</h2>
                  <div className="font-h5 font-weight-normal">
                    Diseñamos soluciones para aumentar la eficacia y eficiencia
                    de los procesos de nuestros clientes.
                  </div>
                  <div className="mt-4 pt-1">
                    <a href="/nosotros" className="btn btn-primary-outline">
                      CONOZCA MÁS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="number container py-5">
          <div className="row text-center font-h5 font-weight-normal py-lg-5">
            <div className="col-6 col-md-3 mb-5 mb-lg-0">
              <Counter
                Number="5"
                Title={
                  <>
                    años de
                    <br />
                    experiencia
                  </>
                }
              />
            </div>
            <div className="col-6 col-md-3 mb-5 mb-lg-0">
              <Counter
                Number="400"
                Title={
                  <>
                    clientes
                    <br />
                    satisfechos
                  </>
                }
              />
            </div>
            <div className="col-6 col-md-3">
              <Counter
                Number="10"
                Title={
                  <>
                    desarrollos
                    <br />
                    de aplicativos
                  </>
                }
              />
            </div>
            <div className="col-6 col-md-3">
              <Counter
                Number="5"
                Title={
                  <>
                    proyectos
                    <br />
                    propios
                  </>
                }
              />
            </div>
          </div>
        </div>

        <div className="testimonials bg-gray py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img
                  src={"/assets/images/testimonials.png"}
                  className="d-block w-100"
                  alt=""
                />
              </div>

              <div className="col-lg-7">
                <div className="pl-lg-4 box-testimonial">
                  <h2 className="font-lg-h1 heading">Testimonios</h2>
                  <p>El éxito de nuestros clientes es nuestro éxito.</p>

                  <TinySlider settings={settingTestimony}>
                    <Testimony
                      Message="Ha sido fabuloso trabajar con Doous y con todo su equipo, entre todos hemos llegado al objetivo y se ha cumplido todas las expectativas satisfactoriamente."
                      Author="Roy Shicshe - Jefe de Sistemas en El Cisne S.A.C."
                    />

                  </TinySlider>

                  <div className="pager-testimonials"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PageIndex;
