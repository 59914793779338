import React from "react";
// import ReactDOM from "react-dom";
import {hydrate, render} from 'react-dom';
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import "./Style.scss";

/*
ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById("root")
);
*/


const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(
        <BrowserRouter>
            <App/>
        </BrowserRouter>, rootElement
    );
} else {
    render(
        <BrowserRouter>
            <App/>
        </BrowserRouter>, rootElement
    );
}
