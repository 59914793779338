import React from "react";
import { Helmet } from "react-helmet";
import Data from "./../data/Data";

const Head = (props) => {
  const { siteName } = Data;
  const { Title, Description } = props;

  let isSiteName = props.isSiteName;

  if (!isSiteName) {
    isSiteName = `${Title} - ${siteName}`;
  } else {
    isSiteName = `${Title}`;
  }

  return (
    <Helmet>
      <title>{isSiteName}</title>
      <meta name="description" content={Description} />
    </Helmet>
  );
};

export default Head;
