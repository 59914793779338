import React, { Fragment, Component } from "react";
import { NavLink } from "react-router-dom";
import Config from "./../data/Data";

class Menu extends Component {
  handleClose = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const resp = document.querySelector(".responsive");

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");

    resp.classList.remove("active");
    resp.style.height = "100%";

    setTimeout(function () {
      resp.style.height = "0%";
    }, 0);

    document.querySelector(".close").classList.remove("active");
    document.querySelector(".bars").classList.add("active");
  };

  handleClick = () => {
    const resp = document.querySelector(".responsive");

    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
    resp.classList.add("active");
    resp.style.height = "0%";

    setTimeout(function () {
      resp.style.height = "100%";
    }, 0);

    document.querySelector(".bars").classList.remove("active");
    document.querySelector(".close").classList.add("active");
  };

  handleClickWhatsApp = () => {
    let whatsappLink = 'https://bit.ly/3PkcjaG';
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      whatsappLink = 'https://wa.link/ni7zd0';
    }
    window.open(whatsappLink, '_blank');
  };

  render() {
    const { numberWhatsapp } = Config;

    const formatWhatapp = numberWhatsapp
      .toString()
      .replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ");

    return (
      <Fragment>
        <div className="h3 mb-0 d-lg-none menu">
          <span className="active bars" onClick={this.handleClick}>
            <i className="fas fa-bars"></i>
          </span>
          <span className="close" onClick={this.handleClose}>
            <i className="far fa-times-circle"></i>
          </span>
        </div>
        <div className="responsive">
          <div className="list-unstyled d-lg-flex align-items-center m-0">
            <div className="responsive-item">
              <NavLink
                to={`/`}
                exact
                className="none text-dark"
                activeClassName="active"
                onClick={this.handleClose}
              >
                Inicio
              </NavLink>
            </div>
            <div className="ml-lg-5 responsive-item">
              <NavLink
                to={`/nosotros`}
                className="none text-dark"
                activeClassName="active"
                onClick={this.handleClose}
              >
                Nosotros
              </NavLink>
            </div>
            <div className="ml-lg-5 responsive-item">
              <NavLink
                to={`/facturacion-electronica`}
                className="none text-dark"
                activeClassName="active"
                onClick={this.handleClose}
              >
                Facturación
              </NavLink>
            </div>
            <div className="ml-lg-5 responsive-item">
              <NavLink
                to={`/consultoria`}
                className="none text-dark"
                activeClassName="active"
                onClick={this.handleClose}
              >
                Consultoría
              </NavLink>
            </div>
            <div className="ml-lg-5 responsive-item">
              <NavLink
                to={`/laboratorio`}
                className="none text-dark"
                activeClassName="active"
                onClick={this.handleClose}
              >
                Labs
              </NavLink>
            </div>
            <div className="d-sm-flex menu-contact align-items-center responsive-item last">
              <div className="menu-contact-whatsapp ml-lg-5">
                <div
                  style={{cursor: 'pointer'}}
                  onClick={this.handleClickWhatsApp}
                  className="text-none btn btn-sm btn-outline-success btn-auto"
                  rel="noreferrer"
                >
                  <span className="d-flex align-items-center">
                    <span className="h4 mb-0 icon">
                      <i className="fab fa-whatsapp"></i>
                    </span>
                    <span className="name">(+51) {formatWhatapp}</span>
                  </span>
                </div>
              </div>
              <div className="menu-contact-form ml-lg-4">
                <NavLink
                  to={`/contacto`}
                  className="btn btn-sm btn-primary btn-auto"
                  onClick={this.handleClose}
                >
                  <span className="h5 mb-0 icon">
                    <i className="fas fa-envelope"></i>
                  </span>
                  <span className="name">Contáctenos</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Menu;
