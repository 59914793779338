import Slider from "../components/Slider";
import TinySlider from "tiny-slider-react";
import React from "react";
import Loadable from 'react-loadable';


const LoadableSlider = Loadable({
    loader: () => import('./Slider.js'),
    loading() {
        return <div>Loading...</div>
    }
});

export default LoadableSlider;
