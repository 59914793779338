import React from "react";

const Testimony = (props) => {
  const { Message, Author } = props;

  return (
    <blockquote>
      <p>{Message}</p>
      <p className="text-right">
        <small className="font-weight-medium">{Author}</small>
      </p>
    </blockquote>
  );
};

export default Testimony;
